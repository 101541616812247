import { computed, nextTick, ref } from 'vue';
import { defineStore } from 'pinia';
import * as Forgot from '~/static/urls/forgot';
import useClearFetchStore from '@/stores/useClearFetchStore';
import { SMS_TIME } from '@/utils/constants';
import { useNuxtApp } from '#imports';

const useForgotPasswordStore = defineStore(
  'forgotPassword',
  () => {
    const userData = ref(null);
    const forgotPasswordData = ref(null);
    const otpVerifyData = ref(null);
    const updateData = ref(null);
    const countDownTimer = ref(SMS_TIME);
    const isResendLink = ref(false);
    const passwordStep = ref(1);
    const isExpireTimeout = ref(false);

    const clearFetch = useClearFetchStore();
    const showToastMessage = (message, isSuccess = false) => {
      if (process.client && !isSuccess) {
        useNuxtApp().$toast.error(message, { autoClose: 5000 });
      } else if (process.client && isSuccess) {
        useNuxtApp().$toast.success(message, { autoClose: 5000 });
      }
    };
    const getExpiredTimeout = computed(() => isExpireTimeout.value);
    const startTimer = () => {
      const timeout = setInterval(() => {
        if (getExpiredTimeout.value) {
          clearInterval(timeout);
        }
        if (countDownTimer.value > 1) {
          countDownTimer.value--;
          isResendLink.value = false;
        } else {
          isResendLink.value = true;
          clearInterval(timeout);
        }
      }, 1000);
    };
    const resetTimer = () => {
      countDownTimer.value = SMS_TIME;
      isResendLink.value = false;
      isExpireTimeout.value = true;
    };
    const handleForgotPass = async (userRequestData) => {
      resetTimer();
      countDownTimer.value--;
      userData.value = userRequestData;
      await clearFetch.clearPost(Forgot.API_POST_FORGOT_PASSWORD, userRequestData);
      if (clearFetch.storeData) {
        isExpireTimeout.value = false;
        await nextTick(() => {
          startTimer();
          forgotPasswordData.value = clearFetch.storeData.data;
          passwordStep.value = 2;
        });
      }
    };

    const resendOtp = async () => {
      countDownTimer.value = SMS_TIME;
      await handleForgotPass(userData.value);
      passwordStep.value = 3;
    };
    const verifyOtp = async (otpData) => {
      for (const prop in userData.value) {
        otpData[prop] = userData.value[prop];
      }
      await clearFetch.clearPost(Forgot.API_POST_VERIFY_OTP, otpData);
      if (clearFetch.error) {
        showToastMessage(clearFetch.message);
      }
      if (clearFetch.storeData) {
        otpVerifyData.value = clearFetch.storeData.data;
        passwordStep.value = 4;
      }
    };
    const updatePassword = async (passwordData) => {
      for (const prop in userData.value) {
        passwordData[prop] = userData.value[prop];
      }
      for (const prop in otpVerifyData.value) {
        if (prop === 'new_otp') {
          passwordData.otp = otpVerifyData.value[prop];
        }
      }
      await clearFetch.clearPost(Forgot.API_POST_UPDATE_PASSWORD, passwordData);
      if (clearFetch.error) {
        showToastMessage(clearFetch.message);
      }
      if (clearFetch.storeData) {
        updateData.value = clearFetch.storeData.data;
        passwordStep.value = 5;
      }
    };
    const resetState = () => {
      userData.value = null;
      forgotPasswordData.value = null;
      otpVerifyData.value = null;
      updateData.value = null;

      resetTimer();
      passwordStep.value = 1;
    };
    const clearForgotData = () => {
      userData.value = null;
      forgotPasswordData.value = null;
      otpVerifyData.value = null;
      updateData.value = null;
      countDownTimer.value = SMS_TIME;
      isResendLink.value = false;
      passwordStep.value = 1;
      isExpireTimeout.value = false;
    };
    const getTimer = computed(() => countDownTimer.value);
    const getStepValue = computed(() => passwordStep.value);
    const getCurrentStatusCode = computed(() => clearFetch.getStatus);
    return {
      // actions
      handleForgotPass,
      verifyOtp,
      updatePassword,
      resendOtp,
      resetState,
      resetTimer,
      clearForgotData,

      forgotPasswordData,
      countDownTimer,
      isResendLink,
      otpVerifyData,
      updateData,
      passwordStep,
      getTimer,
      getStepValue,
      getCurrentStatusCode
    };
  },
  {
    persist: false
  }
);

export default useForgotPasswordStore;
